import React from "react"
import "./carouselCard.scss"
import { Typography } from "@material-ui/core"
import {
  getArticleDateFormat,
  getDefaultArticleCategory,
} from "../../helpers/stringHelper"
import { navigate } from "gatsby"

const CarouselCard = ({
  slug,
  thumbnail,
  category,
  subCategory,
  title,
  date,
}) => {
  const navigateToDetail = ({ category, slug }) => {
    navigate(`${getDefaultArticleCategory({ category: category })}/${slug}`)
  }

  return (
    <div
      className="carousel-card"
      onClick={() => {
        navigateToDetail({ slug: slug, category: category })
      }}
    >
      <img src={thumbnail} className="bg-img" />
      <div className="title-date">
        <Typography className="category">{`${category} | ${subCategory}`}</Typography>
        <Typography className="title">{title}</Typography>
        <Typography className="date">{getArticleDateFormat(date)}</Typography>
      </div>
    </div>
  )
}

export default CarouselCard
