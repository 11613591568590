import React, { useEffect, useState } from "react"
import CarouselCard from "./carouselCard.js"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/bundle"
import { Autoplay, EffectCoverflow, Navigation, Pagination } from "swiper"
import { Typography } from "@material-ui/core"
import "./sectionCarousel.scss"
import i18next, { t } from "i18next"
import axios from "axios"

const BlogSectionCarousel = () => {
  const [articleResponse, setArticleResponse] = useState({
    currentPage: 0,
    totalPage: 0,
    totalDbItem: 0,
    items: [],
  })

  async function getArticleList() {
    const response = await axios.get(
      `${process.env.GATSBY_API_URL}/api/scpublic/article`,
      {
        params: {
          page: 1,
          size: 8,
          isSetOnHighlight: true,
          language: i18next.language,
        },
      }
    )
    setArticleResponse(response.data)
  }

  useEffect(() => {
    getArticleList()
  }, [i18next.language])

  return (
    <div className="section-carousel">
      <div className="title-desc">
        <Typography className="title">{t("blog.highlight")}</Typography>
        <Typography className="desc">{t("blog.highlight-desc")}</Typography>
      </div>
      {articleResponse.items.length == 0 ? (
        <></>
      ) : (
        <Swiper
          effect={"coverflow"}
          centeredSlides={true}
          loop={true}
          autoplay={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 100,
            modifier: 1,
            depth: 750,
            slideShadows: false,
          }}
          pagination={{
            clickable: true,
          }}
          spaceBetween={760}
          modules={[Autoplay, EffectCoverflow, Pagination, Navigation]}
          className="swiper_container"
          slideToClickedSlide
        >
          {articleResponse.items.map((e, i) => (
            <SwiperSlide key={i} className="swiper-slide">
              <CarouselCard
                slug={e.slug}
                thumbnail={e.thumbnail}
                category={e.category}
                subCategory={e.subcategory}
                title={e.title}
                date={e.publishedTime}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  )
}

export default BlogSectionCarousel
