import React from "react"
import { withTrans } from "../../i18n/withTrans"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BlogHeader from "../../components/Blog/header"
import { t } from "i18next"
import BlogSectionCarousel from "../../components/Blog/sectionCarousel"
import BlogSectionList from "../../components/Blog/sectionBlogList"
import { navigate } from "gatsby"
import "./style.scss"

const BlogPage = () => {
  return (
    <Layout>
      <Seo title="Blog" key={0}></Seo>
      <BlogHeader />
      <BlogSectionCarousel />
      <BlogSectionList
        title={t("blog.industry-update")}
        seeAllText={`${t("blog.see-all-about")} ${t("blog.industry-wording")}`}
        onSeeAllClick={() => {
          navigate("/blog/industry")
        }}
        category={"Industry"}
        useHeader={true}
      />
      <BlogSectionList
        title={t("blog.company-update")}
        seeAllText={`${t("blog.see-all-about")} ${t("blog.company-wording")}`}
        onSeeAllClick={() => {
          navigate("/blog/company")
        }}
        category={"Company"}
        useHeader={true}
      />
    </Layout>
  )
}

export default withTrans(BlogPage)
